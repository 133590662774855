import type { BaseConnectionProps } from "@/components/data/connect/BaseConnection"
import { Box, Button, CopyButton, Divider, Stack } from "@mantine/core"
import { modals } from "@mantine/modals"
import { IconCopy, IconCopyCheck, IconQrcode } from "@tabler/icons-react"
import { QRCodeCanvas } from "qrcode.react"

export const ManualConnection: React.FC<Omit<BaseConnectionProps, "value">> = ({
  inbound,
}) => {
  const subscriptionUrl = inbound.subscriptionUrl
  const vLessUrl = inbound.links.join("\n")

  const showQrCode = () =>
    modals.open({
      modalId: "qr-code",
      title: "QR код",
      children: (
        <Box ta="center">
          <QRCodeCanvas
            value={subscriptionUrl}
            size={300}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            level={"L"}
            marginSize={3}
            imageSettings={{
              src: "/logo.svg",
              x: undefined,
              y: undefined,
              height: 44,
              width: 44,
              excavate: true,
            }}
          />
        </Box>
      ),
    })

  return (
    <Stack gap="xs">
      <CopyButton value={subscriptionUrl}>
        {({ copied, copy }) => (
          <Button
            color={copied ? "teal" : "blue"}
            leftSection={copied ? <IconCopyCheck /> : <IconCopy />}
            variant={copied ? "light" : "default"}
            onClick={copy}
            fullWidth
          >
            {copied ? "Ссылка скопирована" : "Скопировать ссылку подписки"}
          </Button>
        )}
      </CopyButton>

      <Divider label={"или"} />

      <CopyButton value={vLessUrl}>
        {({ copied, copy }) => (
          <Button
            color={copied ? "teal" : "blue"}
            leftSection={copied ? <IconCopyCheck /> : <IconCopy />}
            variant={copied ? "light" : "default"}
            onClick={copy}
            fullWidth
          >
            {copied ? "Ссылка скопирована" : "Скопировать ссылку VLess"}
          </Button>
        )}
      </CopyButton>

      <Divider label={"или"} />

      <Button
        color="blue"
        onClick={showQrCode}
        fullWidth
        leftSection={<IconQrcode />}
        variant="default"
      >
        Показать QR код
      </Button>
    </Stack>
  )
}
