import {BaseConnectionPoint} from "../../../components/data/connect/BaseConnection";
import {Accordion, Badge, Button, CopyButton, List, Stack} from "@mantine/core";
import {IconCopy, IconCopyCheck, IconExternalLink} from "@tabler/icons-react";
import {Link} from "@tanstack/react-router"

<BaseConnectionPoint index={1}>Установите приложение</BaseConnectionPoint>

Нажмите на ссылку ниже, чтобы загрузить программу

<Button
  key={'github'}
  variant="light"
  fullWidth
  size="sm"
  component={Link}
  to={''}
  target="_blank"
  rightSection={
    <Badge size="md" radius="sm" variant="outline">
      2.0.5
    </Badge>
  }
>
  Загрузить с Github
</Button>

<BaseConnectionPoint index={2}>Добавьте конфигурацию</BaseConnectionPoint>

Нажмите на кнопку ниже, чтобы добавить конфигурации в программу

<Button size="sm" leftSection={<IconExternalLink size={20} />}>Перейти в приложение</Button>

<Accordion>
  <Accordion.Item value={'s'}>
    <Accordion.Control>Если ссылка не открывается</Accordion.Control>
    <Accordion.Panel>
      <Stack>
        <CopyButton value={''}>
          {({ copied, copy }) => (
            <Button
              size="sm"
              color={copied ? "teal" : "blue"}
              leftSection={copied ? <IconCopyCheck size={20} /> : <IconCopy size={20} />}
              variant={copied ? "light" : "default"}
              onClick={copy}
              fullWidth
            >
              {copied ? "Ссылка скопирована" : "Скопировать ссылку подписки"}
            </Button>
          )}
        </CopyButton>

        <List type="ordered">
          <List.Item>Скопируйте ссылку подписки по кнопке выше</List.Item>
          <List.Item>В программе добавьте новую подписку через `+`</List.Item>
          <List.Item>Название может быть любым</List.Item>
          <List.Item>Вставьте ссылку в поле URL</List.Item>
          <List.Item>Галочка автоматического обновления должна быть отмечена</List.Item>
        </List>
      </Stack>
    </Accordion.Panel>
  </Accordion.Item>
</Accordion>

<BaseConnectionPoint index={3}>Настройки запуска</BaseConnectionPoint>

В Проводнике перейти по пути установки (обычно `C:\Program Files\hiddify`), из меню, вызываемом по нажатию правой кнопкой мыши на файле **HiddifyNext.exe** выбрать Свойства и в открывшемся окне перейти на вкладку Совместимость.

Далее нажать на кнопку `Изменить параметры для всех пользователей` и отметить чекбокс `Запускать эту программу от имени администратора` и нажать на OK.

![](https://placehold.co/600x400/000000/FFF)

<BaseConnectionPoint index={4}>Настройки программы</BaseConnectionPoint>

Перезапустите программу

> Обратите внимание, что после закрытия окна, программа остается в трее, чтобы закрыть программу, нажмите правой кнопкой мыши на иконку программы в трее и выберите пункт `Выход`

<Accordion>
  <Accordion.Item value={'s'}>
    <Accordion.Control>Что такое "трей"?</Accordion.Control>
    <Accordion.Panel>
      Это область, в которой отображаются значки программ, работающих в фоновом режиме. Она находится в правом нижнем углу экрана, рядом с часами.

      ![](https://filestore.community.support.microsoft.com/api/images/1dd2b9d9-8efa-482b-8d5a-bc6871dd1a31?upload=true)
    </Accordion.Panel>
  </Accordion.Item>
</Accordion>

Переходим на вкладку «Главная», выбираем икноку с ползунками, в окне выбираем VPN (как на скриншоте)

![](https://placehold.co/600x400/000000/FFF)

<BaseConnectionPoint index={'4.1'}>Регион</BaseConnectionPoint>

Перейдите во вкладку «Параметры конфигурации», измените «Регион» на «Другой»

![](https://placehold.co/600x400/000000/FFF)

<BaseConnectionPoint index={5}>Запуск</BaseConnectionPoint>

Теперь вы можете использовать приложение, для начала работы нажмите на кнопку **Подключиться** (круглая кнопка в центре)

![](https://placehold.co/600x400/000000/FFF)

<BaseConnectionPoint index={6}>Выбор страны</BaseConnectionPoint>

Чтобы выбрать страну, после подключения перейдите в раздел `Прокси` и выберите страну из списка

![](https://placehold.co/600x400/000000/FFF)
