import type { BaseConnectionProps } from "@/components/data/connect/BaseConnection"
import { BaseConnection } from "@/components/data/connect/BaseConnection"
import { InvisibleManApp } from "@/components/data/connect/apps/InvisibleManApp"
import Hiddify from "@/markdown/connection/windows/hiddify.mdx"
import type { App } from "@/services/apps/apps"
import { Accordion, Badge, Group } from "@mantine/core"
import type { InboundEntityExternal } from "@vpnox/sdk"
import { Suspense } from "react"

import { useMDXComponents } from "@/components/mdx-elements"
import { MDXProvider } from "@mdx-js/react"
import classes from "./AppsAccordion.module.css"

type AppsAccordionProps = {
  apps: App[]
  inbound: InboundEntityExternal
}

const getConnectionComponent = (props: BaseConnectionProps) => {
  switch (props.value.name) {
    case "Invisible Man": {
      return <InvisibleManApp {...props} />
    }

    case "Hiddify Next": {
      return <Hiddify />
    }

    default: {
      return <BaseConnection {...props} />
    }
  }
}

export const AppsAccordion: React.FC<AppsAccordionProps> = ({
  apps,
  inbound,
}) => {
  const components = useMDXComponents()

  return (
    <Accordion classNames={classes}>
      {apps?.map(({ icon, ...data }) => (
        <Accordion.Item key={data.name} value={data.name}>
          <Accordion.Control icon={icon}>
            <Group justify="space-between" px={10}>
              {data.name}

              <Group>
                {data.favorite && <Badge size="md">Рекомендуем</Badge>}
              </Group>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <MDXProvider components={components}>
              <Suspense fallback={<>...</>}>
                {getConnectionComponent({
                  value: data,
                  inbound: inbound,
                })}
              </Suspense>
            </MDXProvider>
          </Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  )
}
