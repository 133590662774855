import { Box, Button, Space, Stack, TextInput } from "@mantine/core"
import { useForm } from "@mantine/form"
import { zodResolver } from "mantine-form-zod-resolver"
import type React from "react"
import { z } from "zod"

const deviceCreateSchema = z.object({
  name: z.string().min(2, { message: "Name should have at least 2 letters" }),
})

export type DeviceCreateFormValues = z.infer<typeof deviceCreateSchema>

export type DeviceCreateFormProps = {
  onSubmit: (values: DeviceCreateFormValues) => void
  loading?: boolean
  footer?: React.ReactNode
  buttonText?: string
  style?: React.CSSProperties
}

export const DeviceCreateForm: React.FC<DeviceCreateFormProps> = ({
  onSubmit: handleSubmit,
  loading,
  footer,
  buttonText = "Добавить устройство",
  style,
}) => {
  const form = useForm<DeviceCreateFormValues>({
    mode: "uncontrolled",
    initialValues: {
      name: "Устройство 1",
    },
    validate: zodResolver(deviceCreateSchema),
  })

  return (
    <form
      onSubmit={form.onSubmit(handleSubmit)}
      style={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        ...style,
      }}
    >
      <TextInput
        label="Название"
        description="Оно будет доступно только вам в личном кабинете"
        placeholder="Имя устройства"
        withAsterisk
        autoFocus
        {...form.getInputProps("name")}
      />

      <Space />
      <Stack mt="auto">
        {footer}
        <Box
          style={{
            position: "sticky",
            bottom: 0,
          }}
        >
          <Button loading={loading} type="submit">
            {buttonText}
          </Button>
        </Box>
      </Stack>
    </form>
  )
}
