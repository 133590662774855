export const APPS_JSON = {
  IOS: {
    Streisand: {
      name: "Streisand",
      links: [
        {
          name: "App Store",
          url: "https://apps.apple.com/ru/app/streisand/id6450534064",
        },
      ],
      connectUrlMask: "streisand://import/URL#NAME",
      favorite: true,
    },
    V2Box: {
      name: "V2Box",
      links: [
        {
          name: "App Store",
          url: "https://apps.apple.com/ru/app/v2box-v2ray-client/id6446814690",
        },
      ],
      connectUrlMask: "v2box://install-sub?url=URL&name=NAME",
      favorite: false,
    },
    SingBox: {
      name: "Sing-Box",
      links: [
        {
          name: "App Store",
          url: "https://apps.apple.com/ru/app/sing-box/id6451272673",
        },
      ],
      connectUrlMask: "sing-box://import-remote-profile?url=URL#NAME",
      favorite: false,
    },
    Shadowrocket: {
      name: "Shadowrocket",
      links: [
        {
          name: "App Store",
          url: "https://apps.apple.com/ru/app/shadowrocket/id932747118",
        },
      ],
      connectUrlMask: "sub://URL",
      favorite: false,
    },
  },
  Android: {
    v2rayNG: {
      name: "v2rayNG",
      links: [
        {
          name: "Github",
          url: "https://github.com/2dust/v2rayNG/releases/download/1.9.3/v2rayNG_1.9.3_universal.apk",
          version: "1.9.3",
        },
        {
          name: "Google Play",
          url: "https://play.google.com/store/apps/details?id=com.v2ray.ang",
          version: "1.9.3+",
        },
      ],
      connectUrlMask: "v2rayng://install-sub?url=URL&name=NAME",
      favorite: true,
    },
    Hiddify: {
      name: "Hiddify Next",
      links: [
        {
          name: "Github",
          url: "https://github.com/hiddify/hiddify-next/releases/download/v2.3.1/Hiddify-Android-universal.apk",
          version: "2.3.1",
        },
        {
          name: "Google Play",
          url: "https://play.google.com/store/apps/details?id=app.hiddify.com",
          version: "2.3.1+",
        },
      ],
      connectUrlMask: "hiddify://install-config/?url=URL",
      favorite: false,
    },
    V2Box: {
      name: "V2Box",
      links: [
        {
          name: "Google Play",
          url: "https://play.google.com/store/apps/details?id=dev.hexasoftware.v2box",
        },
      ],
      connectUrlMask: "v2box://install-sub?url=URL&name=NAME",
      favorite: false,
    },
    SingBox: {
      name: "Sing-Box",
      links: [
        {
          name: "Google Play",
          url: "https://play.google.com/store/apps/details?id=io.nekohasekai.sfa",
        },
      ],
      connectUrlMask: "sing-box://import-remote-profile?url=URL#NAME",
      favorite: false,
    },
  },
  Windows: {
    Hiddify: {
      name: "Hiddify Next",
      links: [
        {
          name: "Github",
          url: "https://github.com/hiddify/hiddify-next/releases/download/v2.3.1/Hiddify-Windows-Setup-x64.exe",
          version: "2.3.1",
        },
      ],
      connectUrlMask: "hiddify://install-config/?url=URL",
      favorite: true,
    },
    InvisibleMan: {
      name: "Invisible Man",
      links: [
        {
          name: "Github",
          url: "https://github.com/InvisibleManVPN/InvisibleMan-XRayClient/releases/download/v2.3.2/InvisibleManXRay-x64.zip",
          version: "2.3.2 (x64)",
        },
        {
          name: "Github",
          url: "https://github.com/InvisibleManVPN/InvisibleMan-XRayClient/releases/download/v2.3.2/InvisibleManXRay-x86.zip",
          version: "2.3.2 (x86)",
        },
      ],
      connectUrlMask: "invxray://subscription/URL",
      favorite: false,
    },
  },
  MacOS: {
    Hiddify: {
      name: "Hiddify Next",
      links: [
        {
          name: "Github",
          url: "https://github.com/hiddify/hiddify-next/releases/download/v2.3.1/Hiddify-MacOS.dmg",
          version: "2.3.1 (ARM/Intel)",
        },
      ],
      connectUrlMask: "hiddify://install-config/?url=URL",
      favorite: true,
    },
    Streisand: {
      name: "Streisand",
      links: [
        {
          name: "App Store",
          url: "https://apps.apple.com/ru/app/streisand/id6450534064",
          version: "ARM",
        },
      ],
      connectUrlMask: "streisand://import/URL#NAME",
      favorite: true,
    },
  },
}
