import { HelpButton } from "@/components/buttons/HelpButton"
import { ShareButton } from "@/components/buttons/ShareButton"
import { InboundsList } from "@/components/data/InboundsList"
import { useRouterBackButton } from "@/libs/navigation"
import { Button, Space, Stack, Title } from "@mantine/core"
import { IconSquareRoundedPlus } from "@tabler/icons-react"
import { Link, Outlet, createFileRoute } from "@tanstack/react-router"
import { zodSearchValidator } from "@tanstack/router-zod-adapter"
import {
  getUsersControllerLoginTelegramQueryOptions,
  useUsersControllerLoginTelegramSuspense,
} from "@vpnox/sdk"
import type React from "react"
import { z } from "zod"

const refSearch = z.object({
  ref: z.number().optional(),
})

export const Route = createFileRoute("/_dashboard/")({
  loader: async ({ context: { queryClient } }) => {
    await queryClient.ensureQueryData(
      getUsersControllerLoginTelegramQueryOptions(),
    )
  },
  component: DashboardComponent,
  validateSearch: zodSearchValidator(refSearch),
})

function DashboardComponent() {
  const {
    data: { inboundLimit, inbounds = [] },
  } = useUsersControllerLoginTelegramSuspense()

  const isMaxInbounds = (inbounds.length || 0) >= inboundLimit

  useRouterBackButton(true)

  return (
    <>
      <Title>Ваши устройства</Title>
      <InboundsList />
      <Button
        component={Link}
        leftSection={
          !isMaxInbounds && <IconSquareRoundedPlus size={24} strokeWidth={2} />
        }
        to={"/devices/create"}
        disabled={isMaxInbounds}
      >
        {!isMaxInbounds ? "Добавить устройство" : "Достигнут лимит устройств"}
      </Button>
      <Space />
      <Stack mt="auto">
        <HelpButton />
        <ShareButton />
      </Stack>
      <Outlet />
    </>
  )
}
