import { Alert, rgba, useMantineTheme } from "@mantine/core"

import cx from "clsx"
import classes from "./MdxInfo.module.css"

export function MdxInfo({
  className,
  children,
}: React.ComponentPropsWithoutRef<"blockquote">) {
  const theme = useMantineTheme()

  return (
    <Alert
      className={cx(classes.root, className)}
      component={"blockquote"}
      __vars={{
        "--docs-bq-code-bg-light": rgba(theme.colors.blue[6], 0.2),
        "--docs-bq-code-bg-dark": rgba(theme.colors.blue[4], 0.2),
      }}
    >
      {children}
    </Alert>
  )
}
