import { Dashboard } from "@/components/data/Dashboard"
import { MainContent } from "@/components/layouts/MainContent"
import { Outlet, createFileRoute } from "@tanstack/react-router"

export const Route = createFileRoute("/_dashboard")({
  component: DashboardComponent,
})

function DashboardComponent() {
  // const [opened, setOpened] = useState(true)
  // const { step, nextStep, isFirstStep, isLastStep, completed } = useOnboarding()

  return (
    <>
      <Dashboard />

      <MainContent>
        <Outlet />
      </MainContent>

      {/*<Modal.Root opened={opened} onClose={console.log} fullScreen radius={0}>*/}
      {/*  <Modal.Content>*/}
      {/*    <Modal.Body*/}
      {/*      style={{*/}
      {/*        display: "flex",*/}
      {/*        flexDirection: "column",*/}
      {/*        height: "100%",*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <Match*/}
      {/*        value={step}*/}
      {/*        registration={() => <OnboardingWelcome />}*/}
      {/*        bonus={() => <OnboardingBonus />}*/}
      {/*        device={() => <OnboardingDevice />}*/}
      {/*        client={() => <OnboardingClient />}*/}
      {/*        settings={() => <OnboardingSettings />}*/}
      {/*        connect={() => <OnboardingConnect />}*/}
      {/*        done={() => <OnboardingDone />}*/}
      {/*      />*/}

      {/*      <Stack pb="md">*/}
      {/*        {!isLastStep && (*/}
      {/*          <>*/}
      {/*            <Button fullWidth onClick={nextStep} disabled={!completed}>*/}
      {/*              Далее*/}
      {/*            </Button>*/}
      {/*            {step === "bonus" && (*/}
      {/*              <Button*/}
      {/*                fullWidth*/}
      {/*                variant="transparent"*/}
      {/*                onClick={() => setOpened(false)}*/}
      {/*              >*/}
      {/*                Пропустить настройку*/}
      {/*              </Button>*/}
      {/*            )}*/}
      {/*          </>*/}
      {/*        )}*/}
      {/*        {isLastStep && (*/}
      {/*          <Button fullWidth onClick={() => setOpened(false)}>*/}
      {/*            Завершить*/}
      {/*          </Button>*/}
      {/*        )}*/}
      {/*      </Stack>*/}
      {/*    </Modal.Body>*/}
      {/*  </Modal.Content>*/}
      {/*</Modal.Root>*/}
    </>
  )
}
