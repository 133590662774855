import {
  DeviceCreateForm,
  type DeviceCreateFormValues,
} from "@/components/forms/DeviceCreateForm"
import { BasicCard } from "@/components/shared/BasicCard"
import { useTitle } from "@/stores/page"
import { List, ThemeIcon, rem } from "@mantine/core"
import { useShallowEffect } from "@mantine/hooks"
import { IconCircleCheck } from "@tabler/icons-react"
import { useQueryClient } from "@tanstack/react-query"
import { createFileRoute } from "@tanstack/react-router"
import { useClosingBehavior } from "@telegram-apps/sdk-react"
import {
  getUsersControllerLoginTelegramQueryKey,
  useInboundsControllerCreate,
} from "@vpnox/sdk"
import React, { useEffect } from "react"

export const Route = createFileRoute("/_page/devices/create")({
  component: DevicesCreateComponent,
})

function DevicesCreateComponent() {
  const { setTitle } = useTitle()
  const navigate = Route.useNavigate()
  const closingBehavior = useClosingBehavior()

  useEffect(() => {
    closingBehavior.enableConfirmation()

    return () => closingBehavior.disableConfirmation()
  }, [closingBehavior])

  useShallowEffect(() => {
    setTitle("Новое устройство")
  }, [setTitle])

  const queryClient = useQueryClient()
  const { mutateAsync: createInbound, isPending } = useInboundsControllerCreate(
    {
      mutation: {
        mutationKey: ["inbounds", "create"],
        async onSuccess(newInbound) {
          // TODO: uncomment this when the backend will return the created inbound
          // queryClient.setQueryData(
          //   getInboundsControllerFindOneQueryKey(newInbound.id),
          //   newInbound,
          // )

          // invalidate all the list queries
          await queryClient.invalidateQueries({
            queryKey: getUsersControllerLoginTelegramQueryKey(),
          })
        },
      },
    },
  )

  const handleSubmit = async (values: DeviceCreateFormValues) => {
    try {
      const inbound = await createInbound({
        data: values,
      })

      void navigate({
        to: "/devices/$id/success",
        params: {
          id: inbound.id,
        },
        replace: true,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <DeviceCreateForm
      onSubmit={handleSubmit}
      loading={isPending}
      footer={
        <BasicCard content>
          <List
            size="sm"
            spacing="xs"
            icon={
              <ThemeIcon color="teal" size={24} radius="xl">
                <IconCircleCheck style={{ width: rem(16), height: rem(16) }} />
              </ThemeIcon>
            }
          >
            <List.Item>
              Для каждого устройства доступно 4 страны: Дания, Нидерланды, США,
              Россия
            </List.Item>
            <List.Item>
              Все сервера имеют высокоскоростное интернет соединение от 2 гбит/с
            </List.Item>
            <List.Item>
              На всех серверах установлен AdGuard для блокировки рекламы
            </List.Item>
            <List.Item>
              При необходимости наша поддержка поможет вам с настройкой и
              ответит на все вопросы
            </List.Item>
          </List>
        </BasicCard>
      }
    />
  )
}
