import { ErrorComponent } from "@/components/layouts/ErrorComponent"
import { MainContent } from "@/components/layouts/MainContent"
import { pageCaptureException } from "@/libs/sentry"
import { Button, Stack } from "@mantine/core"
import {
  Link,
  type NotFoundRouteProps,
  rootRouteId,
  useMatch,
} from "@tanstack/react-router"
import { useLaunchParams } from "@telegram-apps/sdk-react"

export function DefaultNotfound({ data }: NotFoundRouteProps) {
  const isRoot = useMatch({
    strict: false,
    select: state => state.id === rootRouteId,
  })

  const launchParams = useLaunchParams()

  pageCaptureException(new Error("Not found page"), launchParams)

  return (
    <MainContent>
      <ErrorComponent error={new Error("Такой страницы не существует")} />
      <Stack mt="auto">
        {isRoot ? (
          <Button component={Link} to="/" replace>
            На главную
          </Button>
        ) : (
          <Button
            component={Link}
            to="/"
            replace
            onClick={e => {
              e.preventDefault()
              window.history.back()
            }}
          >
            На главную
          </Button>
        )}
      </Stack>
    </MainContent>
  )
}
