import { router } from "@/router"
import * as Sentry from "@sentry/react"
import { captureException } from "@sentry/react"
import type { MutationKey, QueryKey } from "@tanstack/react-query"
import type { LaunchParams } from "@telegram-apps/sdk"

export const startSentry = (initialHash = window.location.hash) => {
  Sentry.init({
    // debug: true, // import.meta.env.MODE === "development",
    dsn: "https://03076624f4ec0f9d2c20f5c63a35eb33@o4506211784785920.ingest.us.sentry.io/4507955675201536", //import.meta.env.VITE_SENTRY_DSN,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.tanstackRouterBrowserTracingIntegration(router),
    ],
    tracePropagationTargets: [
      /^https:\/\/app\.vpnox\.pro/,
      /^https:\/\/vpnox\.ru\.tuna\.am/,
    ],
  })
}

export const queryCaptureException = ({
  error,
  type,
  key,
  variables,
}:
  | {
      error: Error
      type: "query"
      key: QueryKey | undefined
      variables?: any
    }
  | {
      error: Error
      type: "mutation"
      key: MutationKey | undefined
      variables?: any
    }) =>
  captureException(error, s =>
    s.setContext("query", {
      type,
      key: key?.join("."),
      variables,
    }),
  )

export const pageCaptureException = (
  error: Error,
  launchParams: LaunchParams,
) =>
  captureException(error, s =>
    s
      .setUser({
        id: launchParams.initData?.user?.id.toString(),
      })
      .setContext("telegram", {
        version: launchParams.version,
        platform: launchParams.platform,
      }),
  )
