import {
  BaseConnectionBlock,
  type BaseConnectionProps,
} from "@/components/data/connect/BaseConnection"
import { Badge, Button, CopyButton, Group, Stack } from "@mantine/core"
import { IconCopy } from "@tabler/icons-react"
import { Link } from "@tanstack/react-router"

export const InvisibleManApp: React.FC<BaseConnectionProps> = ({
  value,
  inbound,
}) => {
  const url = inbound.links.join("\n")

  return (
    <Stack gap="xl">
      <BaseConnectionBlock
        index={1}
        title={"Установите приложение"}
        description={"Нажмите на ссылку ниже, чтобы загрузить программу"}
      >
        <Stack>
          {value.links.map(link => (
            <Button
              key={link.url}
              variant="light"
              fullWidth
              size="sm"
              component={Link}
              to={link.url}
              target="_blank"
            >
              <Group gap="xs">
                {link.name}{" "}
                {!!link.version && (
                  <Badge size="md" radius="sm" variant="outline">
                    {link.version}
                  </Badge>
                )}
              </Group>
            </Button>
          ))}
        </Stack>
      </BaseConnectionBlock>

      <BaseConnectionBlock
        index={2}
        title={'Выберите "Manage server configuration"'}
        description={
          "В открывшемся окне выбора конфигураций найдите и нажмите на плюс"
        }
      />

      <BaseConnectionBlock
        index={3}
        title={'Выберите "Import from link"'}
        description={
          "Скопируйте link с помощью кнопки ниже и вставьте его в активное поле, после кликните по кнопке Import. Список конфигураций обновится."
        }
      >
        <CopyButton value={url}>
          {({ copied, copy }) => (
            <Button
              variant="light"
              color={copied ? "teal" : "blue"}
              onClick={copy}
              fullWidth
              size="sm"
              leftSection={<IconCopy />}
            >
              {copied ? "Ссылка скопирована" : "Скопировать ссылку vless://"}
            </Button>
          )}
        </CopyButton>
      </BaseConnectionBlock>

      <BaseConnectionBlock index={3} title={"Настройка завершена"}>
        {/*<Stack>*/}
        {/*  <Button*/}
        {/*    variant="light"*/}
        {/*    fullWidth*/}
        {/*    size="md"*/}
        {/*    component={Link}*/}
        {/*    to={"https://2ip.ru"}*/}
        {/*    target="_blank"*/}
        {/*  >*/}
        {/*    Проверить IP и страну – 2ip.ru*/}
        {/*  </Button>*/}
        {/*  <Button*/}
        {/*    variant="light"*/}
        {/*    fullWidth*/}
        {/*    size="md"*/}
        {/*    component={Link}*/}
        {/*    to={"https://speedtest.net"}*/}
        {/*    target="_blank"*/}
        {/*  >*/}
        {/*    Проверить скорость интернета – Speedtest*/}
        {/*  </Button>*/}
        {/*</Stack>*/}
      </BaseConnectionBlock>
    </Stack>
  )
}
