import { router } from "@/router"
import { queryClient } from "@/services/query-client"
import { theme } from "@/theme"
import { MantineProvider } from "@mantine/core"
import { ModalsProvider } from "@mantine/modals"
import { Notifications } from "@mantine/notifications"
import { QueryClientProvider } from "@tanstack/react-query"
import { RouterProvider } from "@tanstack/react-router"
import { SDKProvider } from "@telegram-apps/sdk-react"

export const App = () => {
  return (
    <SDKProvider acceptCustomStyles>
      <MantineProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Notifications limit={1} />
          <ModalsProvider>
            <RouterProvider router={router} />
          </ModalsProvider>
        </QueryClientProvider>
      </MantineProvider>
    </SDKProvider>
  )
}
